import React, { useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Table, Button, Badge, Row, Col } from "react-bootstrap";
import PostJobModal from "./post-job";
import Environment from "../../core/Environment";
import axios from "axios";
import AuthContext from "../../core/AuthContext";
import UpdateJobOpenning from "./EditJobOpeening/edit-job-openning";
import TableShimmerLoader from "../../layouts/ShimmerTable";
import {
  getJobOpeningList,
  getLoading,
} from "../../application/selectors/indexSelector";
import {
  DeleteJob,
  jobDetails,
  jobOpeningList,
} from "../../application/actions/JobOpeningAction";
import { format } from "date-fns";
export default function JobOpening() {
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const [showPostJobModal, setShowPostJobModal] = useState(false);
  const { loading, value } = useSelector(getLoading);
  const JobPostList = useSelector(getJobOpeningList);
  const user = JSON.parse(localStorage.getItem("jwtDetails"));

  // const [JobPostList, setJobPostList] = useState([]);
  console.log(JobPostList);

  const handlePostJobModal = (state) => {
    setShowPostJobModal(state);
  };
  const [showUpdatePostJobModal, setShowUpdatePostJobModal] = useState(false);
  const [postJobData, setPostJobData] = useState("");
  const handleUpdatePostJobModal = (state) => {
    setShowUpdatePostJobModal(state);
  };

  useEffect(() => {
    // loadAllJobPosts();
    dispatch(jobOpeningList(user.clientId));
  }, []);
  const handleUpdateJob = (data, state) => {
    setShowUpdatePostJobModal(state);
    setPostJobData(data);
    dispatch(jobDetails(data.id));
  };
  const handleDeleteJob = (id) => {
    dispatch(DeleteJob({ id: id, clientId: user.clientId }));
  };
  console.log(JobPostList, loading, value);

  return (
    <React.Fragment>
      <Header />
      <PostJobModal
        show={showPostJobModal}
        closeFunction={handlePostJobModal}
        Joblist={() => dispatch(jobOpeningList(user.clientId))} // Pass the function to refresh job list
        token={token} // Pass token if needed for authentication in modal
      />

      <UpdateJobOpenning
        show={showUpdatePostJobModal}
        closeFunction={handleUpdatePostJobModal}
        data={postJobData}
      />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Job Opening</h4>
          </div>

          <div>
            <Button
              type="button"
              variant="dark"
              className="d-flex align-items-center"
              onClick={() => handlePostJobModal(true)}
            >
              <i className="ri-add-line me-1"></i>{" "}
              <span className="align-middle">Post Job</span>
            </Button>
          </div>
        </div>
        <Row className="g-3 mb-4">
          {[
            {
              label: "Applications recieved Today",
              icon: "ri-group-line",
              value: JobPostList?.jobSummary?.ApplicationsReceivedToday,
            },
            {
              label: "Applications recieved this week",
              icon: "ri-group-line",
              value: JobPostList?.jobSummary?.ApplicationsReceivedThisWeek,
            },
            {
              label: "Applications recieved this month",
              icon: "ri-group-line",
              value: JobPostList?.jobSummary?.ApplicationsReceivedThisMonth,
            },
          ].map((card, index) => (
            <Col xs="8" xl="4" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    {card.label}
                  </Card.Title>
                  <h3
                    className={`card-value mb-1 ${
                      card.label === "Pending Approval" ? "text-danger" : ""
                    }`}
                  >
                    <i
                      className={`text-dark ${card.icon} ${
                        card.label === "Pending Approval" ? "text-danger" : ""
                      }${
                        card.label === "Total Veggan Items"
                          ? "text-success"
                          : ""
                      }`}
                    ></i>{" "}
                    {card.value}
                  </h3>
                  {/* <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> {card.des}</small> */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Card className="card-one">
          <Card.Body>
            <>
              {loading ? (
                <TableShimmerLoader
                  colNames={[
                    "JD ID",
                    "Job Title",
                    "No. of Positions",
                    "Total Application",
                    "Pending Acknowledgement",
                    "Shortlisted",
                    "Posted Date",
                    "Status",
                    "Action",
                  ]}
                />
              ) : (
                <Table className="mb-0" responsive>
                  <thead>
                    <tr>
                      <th>JD ID</th>
                      <th className="w-20">Job Title</th>
                      <th>No. of Positions</th>
                      <th>Total Application</th>
                      <th>Pending Acknowledgement</th>
                      <th>Shortlisted</th>
                      <th>Posted Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JobPostList?.listData?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <span className="text-dark">{item.id}</span>
                        </td>
                        <td
                          className={`job-title-td ${
                            item.JobStatus == 1 ? "Live" : "Ended"
                          }`}
                        >
                          <span className="text-dark fw-bold">
                            {item.JobTitle}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark ">
                            {item.NoOfPositions || "-"}
                          </span>
                        </td>
                        <td>
                          <Badge bg="dark" className="fs-14" pill>
                            {item.totalApplicants}
                          </Badge>
                        </td>
                        <td>
                          <Badge bg="danger" className="fs-14" pill>
                            {item.pendingAcknowledgementCount}
                          </Badge>
                        </td>
                        <td>
                          <Badge bg="success" className="fs-14" pill>
                            {item.ShortListedCount}
                          </Badge>
                        </td>
                        <td>
                          <span className="text-dark ">
                            {format(new Date(item.PostedDate), "yyyy-MM-dd")}
                          </span>
                        </td>
                        <td>{/* Status or other info here */}</td>
                        <td className="d-flex justify-content">
                          <Button
                            variant="default"
                            className="btn-icon me-2 text-dark fw-bold"
                            onClick={() => handleUpdateJob(item, true)}
                          >
                            <i className="ri-pencil-line"></i>
                          </Button>
                          <Button
                            variant="default"
                            className="btn-icon text-danger"
                            onClick={() => handleDeleteJob(item.id)}
                          >
                            <i className="ri-delete-bin-line"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
