import React from "react";
import Dashboard from "../View/Dashboard/dashboard";
import AskHRVi from "../View/AskHr/ask-hrvi";
import UserAccount from "../View/UserAccount/user-account";
import System from "../View/System/system";
import JobOpening from "../View/JobOpenings/job-opening";
import Candidates from "../View/Candidates/candidates";
import PrivateRoute from './PrivateRoute';

const protectedRoutes = [
  { path: "/", element: <PrivateRoute element={<AskHRVi />} /> },
  { path: "dashboard/manage", element: <PrivateRoute element={<Dashboard />} /> },
  { path: "askHRVi/manage", element: <PrivateRoute element={<AskHRVi />} /> },
  { path: "userAccount/manage", element: <PrivateRoute element={<UserAccount />} /> },
  { path: "system/manage", element: <PrivateRoute element={<System />} /> },
  { path: "jobOpening/manage", element: <PrivateRoute element={<JobOpening />} /> },
  { path: "candidates/manage", element: <PrivateRoute element={<Candidates />} /> },
];

export default protectedRoutes;
