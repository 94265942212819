import { APPLICANT_LIST_SUCCESS, GET_JOB_DETAILS_SUCCESS, GET_JOB_OPENNING_LIST_SUCCESS } from "../actions/JobOpeningAction";

const initialState = {
    jobOpeningList: undefined,
    jobDetails: undefined,
    candidateList: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_JOB_OPENNING_LIST_SUCCESS:
            return { ...state, jobOpeningList: action.payload, error: null }; 
        case GET_JOB_DETAILS_SUCCESS:
            return { ...state, jobDetails: action.payload.data.jobDetails, error: null };
        case APPLICANT_LIST_SUCCESS:
            
            return { ...state, candidateList: action.payload, error: null };
        default:
            return state;
    }
}

export default reducer;