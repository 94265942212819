import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Tabs, Tab } from "react-bootstrap";
import JobDetails from "./job-details";
import { useSelector } from "react-redux";
import { getJobDetails } from "../../../application/selectors/indexSelector";
import ApplicantList from "./applicantList";

export default function UpdateJobOpenning(props) {

    const handleClose = () => {
        props.closeFunction(false);
    };
const [activeTab, setActiveTab] = useState('edit_details_tab');
const handleTabSelect = (tab) => {
    setActiveTab(tab);
};
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={handleClose} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Job Openning</Offcanvas.Title>
                    
                </Offcanvas.Header>
                <Offcanvas.Body className="update-job-component">
                <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="common-tabs">
                        <Tab eventKey="edit_details_tab" title={<><h6 className="fs-14 mb-0 h-100">Applicant List</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3 pb-5 h-100">
                                <ApplicantList />
                            </div>
                        </Tab>
                        <Tab eventKey="candidate_details_tab" title={<><h6 className="fs-14 mb-0">Job Details</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3 pb-5">
                               <JobDetails  closeFunction={handleClose}/>
                            </div>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
}
