import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Environment from "./Environment";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();
export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    let Token = localStorage.getItem("Token");
    if (Token) {
      return Token;
    }
    return null;
  });
  const [user, setUser] = useState(() => {
    let userDetails = JSON.parse(localStorage.getItem("jwtDetails"));
    if (userDetails) {
      return userDetails;
    }
    return null;
  });
  const navigate = useNavigate();
  const login = async (payload) => {
    try {
      const mydata = {
        username: payload.username,
        password: payload.password,
        domain: payload.domain,
      };
      let response = await axios.post(
        `${Environment.ASK_ALLEN_URL}login`,
        mydata
      );
      const decodedToken = jwtDecode(response.data.access_token);
      localStorage.setItem("jwtDetails", JSON.stringify(decodedToken?.sub));
      setUser(JSON.stringify(decodedToken?.sub));
      // Ensure token is stored as a string in local storage
      localStorage.setItem("Token", JSON.stringify(response.data.access_token));

      setToken(JSON.stringify(response.data.access_token));
      navigate("/askHRVi/manage");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error("Invalid username or password");
      } else {
        console.error("Error during login:", error);
      }
    }
  };
  // function isTokenExpired(token) {
  //   // Decode the token
  //   const decodedToken = jwtDecode(token);

  //   // Check if the token has an expiration claim
  //   if (decodedToken && decodedToken.exp) {
  //     // Get the expiration time in seconds
  //     const expirationTime = decodedToken.exp;

  //     // Get the current time in seconds
  //     const currentTime = Math.floor(Date.now() / 1000);

  //     // Compare the current time with the expiration time
  //     return currentTime >= expirationTime;
  //   }

  //   // If the token doesn't have an expiration claim, consider it expired
  //   return true;
  // }

  const logout = async () => {
    localStorage.clear();
    navigate("/account/login");
  };
  useEffect(() => {
    const storedToken = localStorage.getItem("Token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      navigate("/account/login");
    }
    const jwtDetails = localStorage.getItem("jwtDetails");
    if (jwtDetails) {
      setUser(jwtDetails);
    }
  }, []);
  return (
    <>
      <AuthContext.Provider
        value={{
          token,
          user,
          login,
          logout,
          setUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
