import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import { GET_PDF_LIST, GetPDFSuccess } from "../actions/askAllenChat";

const GetPDFList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PDF_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetPDFList" }));
        const pdfList = await api.askAllenChatAPI.GetPDFList(action.payload);
        dispatch(GetPDFSuccess(pdfList));
        dispatch(Loading.setLoading({ loading: false, value: "GetPDFList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
    GetPDFList
];
