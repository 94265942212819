import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Editor from "react-simple-wysiwyg";
import Environment from "../../../core/Environment";
import axios from "axios";
import AuthContext from "../../../core/AuthContext";
import empType from "../../../json/empType.json";
import { useDispatch, useSelector } from "react-redux";
import { UpdateJob } from "../../../application/actions/JobOpeningAction";
import { getJobDetails } from "../../../application/selectors/indexSelector";
import CandidatesDetails from "../../Candidates/EditCandidates/candidate-details";

function JobDetails({ closeFunction }) {
  const { token } = useContext(AuthContext);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("jwtDetails"));
  const jobdetails = useSelector(getJobDetails);
  const [roleCatList, setRoleCatList] = useState([]);
  useEffect(() => {
    loadAllRoleCategory();
    if (jobdetails) {
      setFormData({
        ...formData,
        job_id: jobdetails.id,
        FunctionalArea: jobdetails.FunctionalArea,
        RoleCategory: jobdetails.RoleCategory,
        JobTitle: jobdetails.JobName,
        Location: jobdetails.Location,
        JobStatus: jobdetails.JobStatus,
        JobSummary: jobdetails.JobSummary,
        AboutJob: jobdetails.AboutJob,
        NoOfPositions: jobdetails.NoOfPositions,
        MiniQualification: jobdetails.MiniQualification,
        ReqQualification: jobdetails.ReqQualification,
        PrefferredLang: jobdetails.PrefferredLang,
        EmpType: jobdetails.EmpType,
        PostedBy: jobdetails.PostedBy,
        ModifiedBy: user.id,
        clientId: user.clientId,
        RequiredSkills: jobdetails.RequiredSkills,
        ExperienceReq: jobdetails.ExperienceReq,
        KeyResponsibilities: jobdetails.KeyResponsibilities,
        AdditionalInfo: jobdetails.AdditionalInfo,
      });
    }
  }, [jobdetails]);
  const [formData, setFormData] = useState({
    FunctionalArea: "",
    RoleCategory: "Software",
    JobTitle: "",
    Location: "",
    JobStatus: "",
    AboutJob: "",
    NoOfPositions: "",
    MiniQualification: "",
    ReqQualification: "",
    PrefferredLang: "",
    EmpType: "FullTime",
    PostedBy: "",
    ModifiedBy: "",
    RequiredSkills: "",
    ExperienceReq: "",
    KeyResponsibilities: "",
  });

  const loadAllRoleCategory = async () => {
    const user = JSON.parse(localStorage.getItem("jwtDetails"));

    try {
      const response = await axios.get(
        `${Environment.ASK_ALLEN_URL}getRoleCatByClientIdEndPoint`,
        {
          params: {
            clientId: user.clientId, // Send clientId as query parameter
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setRoleCatList(response.data); // Ensure this matches the API response structure
    } catch (error) {
      console.error("Error loading job posts:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(UpdateJob(formData));
    closeFunction();
  };
  return (
    <React.Fragment>
      {/* <CandidatesDetails candidateDetail={props.candidateDetail} /> */}
      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Job Status: <sup className="text-danger">*</sup>
            </Form.Label>
            <div className="d-flex align-items-center gap-3">
              <Form.Select
                name="JobStatus"
                value={formData.JobStatus}
                onChange={handleChange}
                required
              >
                <option value={0}>Choose Job Status </option>
                <option value={1}>Live </option>
                <option value={2}>Ended </option>
              </Form.Select>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Role/Title: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="JobTitle"
              placeholder="Enter Role/Title"
              value={formData.JobTitle}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>Functional Area:</Form.Label>
            <Form.Control
              type="text"
              name="FunctionalArea"
              placeholder="Enter Functional Area"
              value={formData.FunctionalArea}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Role Category: <sup className="text-danger">*</sup>
            </Form.Label>
            <div className="d-flex align-items-center gap-3">
              <Form.Select
                name="RoleCategory"
                value={formData.RoleCategory}
                onChange={handleChange}
                required
              >
                <option>Choose Role Category</option>
                {roleCatList?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.RoleCategoryName}
                  </option>
                ))}{" "}
              </Form.Select>
              <Button
                variant="dark"
                className="btn-icon"
                // onClick={() => {
                //   setShowRoleModal(true);
                // }}
              >
                <i className="ri-add-line"></i>
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Employment Type: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select
              name="EmpType"
              value={formData.EmpType}
              onChange={handleChange}
              required
            >
              <option>Choose Employment Type</option>
              {empType?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.value}
                </option>
              ))}
              {/* Add more options here */}
            </Form.Select>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Location: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="Location"
              placeholder="Enter Location"
              value={formData.Location}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Required Experience: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="ExperienceReq"
              placeholder="Enter Experience Required"
              value={formData.ExperienceReq}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              No of Positions: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="NoOfPositions"
              placeholder="Enter No Of Positions"
              value={formData.NoOfPositions}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>Preferred Language:</Form.Label>
            <Form.Control
              type="text"
              name="PrefferredLang"
              placeholder="Enter Preferred Language"
              value={formData.PrefferredLang}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={10} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>Job Summary:</Form.Label>
            <Editor
              type="text"
              name="JobSummary"
              placeholder="Enter Job Summary"
              value={formData.JobSummary}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>Min Qualification:</Form.Label>
            <Editor
              name="MiniQualification"
              placeholder="Enter Min Qualification"
              value={formData.MiniQualification}
              onChange={handleChange}
            ></Editor>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={12} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Required Qualification: <sup className="text-danger">*</sup>
            </Form.Label>
            <Editor
              name="ReqQualification"
              placeholder="Enter Required Qualification"
              value={formData.ReqQualification}
              onChange={handleChange}
            ></Editor>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
            Key Responsibilities: <sup className="text-danger">*</sup>
            </Form.Label>
            <Editor
              name="KeyResponsibilities"
              placeholder="Enter Key Responsibilities"
              value={formData.KeyResponsibilities}
              onChange={handleChange}
            ></Editor>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={12} md={10} sm={12}>
          <div className="mb-4">
            <Form.Label>
              Required Skills: <sup className="text-danger">*</sup>
            </Form.Label>
            <Editor
              name="RequiredSkills"
              placeholder="Enter Required Skills"
              value={formData.RequiredSkills}
              onChange={handleChange}
            ></Editor>
          </div>
        </Col>
        <Row>
            <Col xl={12} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Additional Information:</Form.Label>
                <Editor
                  name="AdditionalInfo"
                  placeholder="Enter Additional Information"
                  value={formData.AdditionalInfo}
                  onChange={handleChange}
                ></Editor>
              </div>
            </Col>
          </Row>
      </Row>
      <div>
        <Button
          type="button"
          variant="primary"
          className="fs-14 me-2 d-flex align-items-center"
          onClick={handleSubmit}
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
          <span className="align-middle">Update</span>
        </Button>
      </div>
    </React.Fragment>
  );
}

export default JobDetails;
