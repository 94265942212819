// import React from "react";
// import { Link } from "react-router-dom";
// import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
// import hrBg from "../../assets/img/account/askHr-bg.jpg";
// import mainLogo from "../../assets/img/logo/image.png";

// export default function LoginComponent() {
//   const handleSubmit = () => {
//     window.location.href = "/dashboard/manage";
//   };
//   return (
//     <div className="page-sign d-block py-0">
//       <Row className="g-0">
//         <Col md="7" lg="4" className="col-wrapper">
//           <Card className="card-sign">
            
//             <Card.Header>
//             <div className="account-logo">
//                 <Image src={mainLogo} className="wt-200" />
//               </div>
//               <Card.Title>Sign In</Card.Title>
//               <Card.Text className="text-dark">
//                 Welcome back! Please Signin to continue.
//               </Card.Text>
//             </Card.Header>

//             <Card.Body>
              
//               <Form>
//                 <div className="mb-4">
//                   <Form.Label>Username</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter your Username"
//                     name="username"
//                   />
//                 </div>

//                 <div className="mb-4">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Enter your password"
//                     name="password"
//                   />
//                 </div>

//                 <Button
//                   type="button"
//                   className="btn-sign"
//                   onClick={handleSubmit}
//                 >
//                   Sign In
//                 </Button>
//                 <div className="text-end mt-1">
//                   <Link to="/account/forgot-password" className="float-end">
//                     Forgot password?
//                   </Link>
//                 </div>
//               </Form>
//             </Card.Body>
//             <Card.Footer>
//               Don't have an account?{" "}
//               <Link to="/account/signup">Create an Account</Link>
//             </Card.Footer>
//           </Card>
//         </Col>

//         <Col className="d-none d-lg-block position-relative">
//           {/* <div className="account-logo">
//             <Image src={mainLogo} className="wt-150" />
//           </div> */}
//           <Image src={hrBg} className="h-100 w-100 opacity-50" alt="bg" />
//         </Col>
//       </Row>
//     </div>
//   );
// }
import React, {  useContext, useState } from "react";
//import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
import hrBg from "../../assets/img/account/askHr-bg.jpg";

import logoDark from "../../assets/img/logo/logo-dark.png";
import logoDark1 from "../../assets/img/logo/logo-dark-1.png";
import logoWhite from "../../assets/img/logo/logo-white.png";
import AuthContext from "../../core/AuthContext";

export default function LoginComponent() {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useContext(AuthContext);
  //const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
      try {
        setLoading(true);
        await login({username:username,password:password});
      } catch (error) {
        setError("Invalid User name and password");
        setLoading(false);
      } finally {
        setLoading(false);
    }
    // try {
    //   const response = await axios.post("http://127.0.0.1:5000/login", {
    //     username,
    //     password,
    //   });
    //   if (response.status === 200) {
    //     localStorage.setItem('access_token', response.data.access_token);
    //     setToken(response.data.access_token)
    //    navigate("/dashboard/manage");
    //   } else {
    //     setError(response.data.message);
    //   }
    // } catch (error) {
    //   setError("An error occurred. Please try again.");
    // }
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <div className="account-logo">
                <Image src={logoDark1} className="wt-200 dark-theme" />
                <Image src={logoWhite} className="wt-200 white-theme" />
              </div>
              <Card.Title>Sign In</Card.Title>
              <Card.Text className="text-dark">
                Welcome back! Please Signin to continue.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form>
                <div className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {error && <p className="text-danger">{error}</p>}
                <Button type="button" className="btn-sign" onClick={handleSubmit}>
                 Let me in
                </Button>
                {/* <div className="text-end mt-1">
                  <Link to="/account/forgot-password" className="float-end">
                    Forgot password?
                  </Link>
                </div> */}
              </Form>
            </Card.Body>
            {/* <Card.Footer>
              Don't have an account?{" "}
              <Link to="/account/signup">Create an Account</Link>
            </Card.Footer> */}
          </Card>
        </Col>
        <Col className="d-none d-lg-block position-relative">
          <Image src={hrBg} className="h-100 w-100 opacity-50" alt="bg" />
          {/* <div className="login-content-logo">
            <Image className="wt-200 dark-theme" src={logoDark} />
            <Image className="wt-200 white-theme" src={logoWhite} />
          </div> */}

          <div className="login-content">
            <h2 className="text-white mb-2">Welcome to Ask Allen</h2>
            <h1 className="text-white">Accelerate your hiring with unmatched precision and speed.</h1>
          </div>
        </Col>
      </Row>
    </div>
  );
}
