import React from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";

export default function System() {
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Manage</li>
                        </ol>
                        <h4 className="main-title mb-0">System</h4>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}