import Environment from "../../../core/Environment";
import jwtInterceptor from "./../../../core/helpers/jwtInterceptor";

const GetCandidateList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.ASK_ALLEN_URL}GetCandidateByClientId?clientId=${data.clientId}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&orderBy=candidateId&direction=DESC`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const AddNewCandidate = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.ASK_ALLEN_URL}saveCandidate`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/formData",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UpdateCandidate = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.ASK_ALLEN_URL}updateCandidate`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const DeleteCandidate = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.ASK_ALLEN_URL}deleteCandidate/${data.id}/${data.clientId}`,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const CandidateDetails = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.ASK_ALLEN_URL}getCandidateDetailsById?candidateId=${data}`,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
export default {
    GetCandidateList,
    AddNewCandidate,
    UpdateCandidate,
    DeleteCandidate,
    CandidateDetails
};