import React, { useState } from "react";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import Environment from "../../core/Environment";
import { useDispatch } from "react-redux";
import { AddCandidate } from "../../application/actions/candidateAction";

export default function AddCandidates(props) {
  const user = JSON.parse(localStorage.getItem("jwtDetails"));

  const [formData, setFormData] = useState({
    candidateId:"",
    candidateName: "",
    emailId: "",
    phoneNumber: "",
    locationName: "",
    Experience: "",
    Education: "",
    Skills: "",
    tags: "",
    Gender: "",
    DOB: "",
    About: "",
    clientId:user.clientId
    // For storing the selected file
  });
  const [file, setFile] = useState(null);
  const [filetypeError, setFiletypeError] = useState(null);
  const handleClose = () => {
    props.closeFunction(false);
  };
const dispatch = useDispatch()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Check if the selected file is a PDF
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      setFile(null); // Reset the file if it's not a PDF
      setFiletypeError("Please upload a PDF file.");
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("data", JSON.stringify(formData));
    data.append("file", file);

    try{
      debugger
   dispatch(AddCandidate(data))
  //  props.Candidates()
      // Handle success
      handleClose();
    } catch (error) {
      console.error("Error adding candidate:", error);
      // Handle error
    }
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New Candidates
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          {/* <Form onSubmit={handleSubmit}> */}
            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="candidateName"
                    value={formData.candidateName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email ID"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Location"
                    name="locationName"
                    value={formData.locationName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Experience</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Experience"
                    name="Experience"
                    value={formData.Experience}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Education</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Education"
                    name="Education"
                    value={formData.Education}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Skills</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Skills"
                    name="Skills"
                    value={formData.Skills}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Tags"
                    name="tags"
                    value={formData.tags}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    name="Gender"
                    value={formData.Gender}
                    onChange={handleChange}
                    required
                  >
                    <option>Choose Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>DOB</Form.Label>
                  <Form.Control
                    type="date"
                    name="DOB"
                    value={formData.DOB}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mb-4">
                  <Form.Label>About</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter About"
                    name="About"
                    value={formData.About}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mb-4">
                  <Form.Label>Upload Resume</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleFileChange}
                    required
                  />
                  <Form.Text className="text-danger">
                    {filetypeError}
                  </Form.Text>
                </div>
              </Col>
            </Row>

           
          {/* </Form> */}
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
              <Button type="button" variant="primary" onClick={handleSubmit} className="fs-14 me-2 d-flex align-items-center">
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                <span className="align-middle">Create</span>
              </Button>
            </div>
      </Offcanvas>
    </React.Fragment>
  );
}
