export const GET_PDF_LIST = 'GET_PDF_LIST';
export const GET_PDF_LIST_SUCCESS = 'GET_PDF_LIST_SUCCESS';

export const GetPDFList = (id) => ({
    type: GET_PDF_LIST,
    payload:id
});
// export const AddCandidate = (data) => ({
//     type: ADD_CANDIDATE,
//     payload:data
// });
// export const UpdateCandidate = (data) => ({
//     type: UPDATE_CANDIDATE,
//     payload:data
// });
// export const DeleteCandidate = (id) => ({
//     type: DELETE_CANDIDATE,
//     payload:id
// });
export const GetPDFSuccess = (data) => ({
    type: GET_PDF_LIST_SUCCESS,
    payload: data,
});

