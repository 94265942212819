// export class Environment {
const live = "https://hrvigateway.cxengine.net/";
const local = "http://127.0.0.1:5000/";
const stage = "";
const defaultValue = "00000000-0000-0000-0000-000000000000";
const baseUrl = stage === "dev" ? local : live;
const ASK_ALLEN_URL = `${baseUrl}`;


export default {
    ASK_ALLEN_URL
};
