import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Card, Button, Col, Form, Row } from "react-bootstrap";
import AddCandidates from "./Create-new-candidates";
import AuthContext from "../../core/AuthContext";
import UpdateCandidates from "./EditCandidates/update-candidates";
import {
  CandidateDetails,
  CandidateList,
} from "../../application/actions/candidateAction";
import { getCandidateList, getLoading } from "../../application/selectors/indexSelector";
import TableShimmerLoader from "../../layouts/ShimmerTable";

export default function Candidates() {
  const { token } = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem("jwtDetails"));
  const [candidateLists, setCandidateList] = useState([]);
  const dispatch = useDispatch();
  const { loading, value } = useSelector(getLoading);

  // Handle Add Candidates
  const [showAddCandidates, setShowAddCandidates] = useState(false);
  const handleAddCandidates = (state) => setShowAddCandidates(state);

  // Handle Update Candidates
  const [showUpdateCandidates, setShowUpdateCandidates] = useState(false);
  const [candidateDetail, setCandidateDetail] = useState(null);
  const handleUpdateCandidates = (state, data) => {
    setShowUpdateCandidates(state);
    if (state && data) {
      dispatch(CandidateDetails(data.candidateId));
      setCandidateDetail(data);
    } else {
      setCandidateDetail(null);
    }
  };

  // Pagination States
  const [page, setPage] = useState(1);
  const [fetchSize] = useState(calculateInitialFetchSize()); // Assuming fetchSize doesn't change dynamically
  const [hasMore, setHasMore] = useState(true);

  function calculateInitialFetchSize() {
    // Example: Set initial fetch size based on the visible height of the window
    const windowHeight = window.innerHeight;
    // Adjust fetch size based on your specific requirements and UI
    return Math.ceil(windowHeight / 80); // Adjust '80' based on item height in pixels
  }

  // Selectors
  const candidatesFromRedux = useSelector(getCandidateList);

  // Fetch Candidates when page changes
  useEffect(() => {
    const data = {
      pageNumber: page,
      pageSize: fetchSize,
      clientId: user.clientId,
      // Include orderBy and direction if required
    };
    dispatch(CandidateList(data));
  }, [page, fetchSize, dispatch, user.clientId]);

  // Update local candidate list when Redux store updates
  useEffect(() => {
    if (candidatesFromRedux && Array.isArray(candidatesFromRedux)) {
      if (page === 1) {
        setCandidateList(candidatesFromRedux);
      } else {
        // Filter out duplicates based on candidateId
        const newCandidates = candidatesFromRedux.filter(
          (newItem) => !candidateLists.some((item) => item.candidateId === newItem.candidateId)
        );
        setCandidateList((prevItems) => [...prevItems, ...newCandidates]);
      }
      // Determine if more data is available
      setHasMore(candidatesFromRedux.length === fetchSize);
    }
  }, [candidatesFromRedux, page, fetchSize, candidateLists]);

  // Handle Scroll for Infinite Scrolling
  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 20 && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, loading]);

  useEffect(() => {
    // Add scroll event listener for infinite scrolling
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up scroll event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  // Function to calculate age from DOB
  function calculateAge(dobString) {
    const dob = new Date(dobString);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();

    // Adjust age if the current date hasn't yet reached the birthday in the current year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }
const [searchTerm, setSearchTerm] = useState('');
const filteredcandidateLists = candidateLists?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <Header />
      <AddCandidates
        show={showAddCandidates}
        closeFunction={handleAddCandidates}
      />

      <UpdateCandidates
        show={showUpdateCandidates}
        closeFunction={() => handleUpdateCandidates(false, null)}
        candidateDetail={candidateDetail}
      />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Candidates</h4>
          </div>

          <div>
            <Row className="mb-4 mt-4 align-items-center d-flex">
            <Col xs={7} xl={6}>
                <div className="custom-dropdown-wrapper justify-content-end d-flex">
                  <div className="custom-drop-down z-index-2 wt-300">
                    {/* <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span> */}
                    <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                  </div>
                </div>
              </Col>
            <Col xs={7} xl={6}>
            <Button
              type="button"
              variant="dark"
              className="fs-14 d-flex align-items-center"
              onClick={() => handleAddCandidates(true)}
            >
              <i className="ri-add-line me-1"></i>{" "}
              <span className="align-middle">Create New Candidates</span>
            </Button></Col>
           
         
            </Row>
            
          </div>
        </div>

        <Card className="card-one">
          <Card.Body>
            {candidateLists && candidateLists.length > 0 ? (
              <Table className="mb-0" responsive>
                <thead>
                  <tr>
                    {/* <th>ID</th> */}
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Location</th>
                    <th>Experience</th>
                    <th>Gender</th>
                    <th>DOB</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredcandidateLists.map((item, index) => (
                    <tr key={item.candidateId || index}>
                      {/* <td>{index}</td> */}
                      <td className="w-20">
                        {item.candidateName}
                        <h6 className="mb-0 fs-12 text-dark ">{item.emailId}</h6>
                      </td>
                      <td >{item.phoneNumber}</td>
                      <td className="w-20">{item.locationName}</td>
                      <td>{item.Experience}</td>
                      <td>{item.Gender}</td>
                      <td>{calculateAge(item.DOB)}</td>
                      <td className="d-flex justify-content gap-2">
                        <Button
                          variant="primary"
                          className="btn-icon"
                          onClick={() => handleUpdateCandidates(true, item)}
                        >
                          <i className="ri-pencil-line"></i>
                        </Button>
                        <Button
                          variant="outline-dark"
                          className="btn-icon"
                        >
                          <a
                            href={item.resume_url}
                            rel="noreferrer"
                            target="_blank"
                            className="text-dark px-2"
                          >
                            <i className="ri-eye-line"></i>
                          </a>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : !loading ? (
              <div>No candidates found.</div>
            ) : (
              <TableShimmerLoader
                colNames={[
                  "ID",
                  "Name",
                  "Phone Number",
                  "Location",
                  "Experience",
                  "Gender",
                  "DOB",
                  "Action",
                ]}
                colsCount={8}
              />
            )}
            {/* Optionally, show a loader at the bottom when fetching more data */}
            {loading && page > 1 && (
              <div className="text-center my-3">
                <span>Loading more candidates...</span>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
