import * as loaderActions from '../actions/loaderAction'
const initialState={
    loading:true,
    value:'default'
}
const reducer=(state=initialState , action)=>{
    switch(action.type){
        case (loaderActions.SET_LOADING_ON):
            return {...state ,loading:action.payload.loading, value:action.payload.value};
        case (loaderActions.SET_LOADING_OFF):
            return {...state ,loading:action.payload.loading, value:action.payload.value};
        default:
            return state;
    }
}
export default reducer