export const GET_CANDIDATES_LIST = 'GET_CANDIDATES_LIST';
export const ADD_CANDIDATE = 'ADD_CANDIDATE';
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE';
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';
export const GET_CANDIDATE_LIST_SUCCESS = 'GET_CANDIDATE_LIST_SUCCESS';
export const GET_CANDIDATE_DETAILS = 'GET_CANDIDATE_DETAILS';
export const GET_CANDIDATE_DETAILS_SUCCESS = 'GET_CANDIDATE_DETAILS_SUCCESS';

export const CandidateList = (id) => ({
    type: GET_CANDIDATES_LIST,
    payload:id
});
export const AddCandidate = (data) => ({
    type: ADD_CANDIDATE,
    payload:data
});
export const UpdateCandidate = (data) => ({
    type: UPDATE_CANDIDATE,
    payload:data
});
export const DeleteCandidate = (id) => ({
    type: DELETE_CANDIDATE,
    payload:id
});
export const GetCandidateSuccess = (data) => ({
    type: GET_CANDIDATE_LIST_SUCCESS,
    payload: data,
});
export const CandidateDetails = (id) => ({
    type: GET_CANDIDATE_DETAILS,
    payload:id
});
export const CandidateDetailsSuccess = (data) => ({
    type: GET_CANDIDATE_DETAILS_SUCCESS,
    payload:data
});
