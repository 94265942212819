import React from "react";
import { Badge, Button, Card, Dropdown, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCandidateListByJobId,
  getJobDetails,
} from "../../../application/selectors/indexSelector";
import { Link } from "react-router-dom";
import applicantStatus from "../../../json/applicantStatus.json";
import { UpdateStatus } from "../../../application/actions/JobOpeningAction";

export default function ApplicantList() {
  const jobdetails = useSelector(getJobDetails);
  const candidateList = useSelector(getCandidateListByJobId);
  console.log(jobdetails);
  
  const dispatch = useDispatch();
  const handleStatus = (applicationId, status) => {
    ;
    dispatch(
      UpdateStatus({
        "ApplicationId": applicationId,
        "Status": status,
        "jobId": jobdetails.id,
        "clientId": jobdetails.clientId,
      })
    );
  };
  return (
    <React.Fragment>
      <Card className="card-one">
        <Card.Body className="h-100">
          <Table className="table mb-0" responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>Location</th>
                <th>Exp.</th>
                <th>Gender</th>
                <th>Resume</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {candidateList?.map((item, index) => (
                <tr key={index}>
                  {/* <td>{item.candidateId}</td> */}
                  <td>
                    {item.candidateName}
                    <h6 className="mb-0 fs-12 text-dark">{item.emailId}</h6>
                  </td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.locationName}</td>
                  {/* <td>{item.DOB}</td> */}
                  <td>{item.Experience}</td>
                  <td>{item.Gender}</td>

                  <td>
                    <Link to={item.resumeFile} target="_blank">
                      <i className="ri-eye-line fs-18 text-dark px-2"></i>
                    </Link>
                  </td>
                  {item.Status === "Pending Acknowledgement" ? (
                    <td>
                     
                    </td>
                  ) : (
                    <td>
                      <Badge bg="dark" className="fs-14" pill>
                        {item.Status}
                      </Badge>
                    </td>
                  )}
                  <td>
                    {item.Status !== "Pending Acknowledgement" ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-dark"
                          className="btn-icon custom-dropdown-action wt-35 ht-35"
                          id="dropdown-basic"
                        >
                          <i className="ri-settings-line fs-18"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {applicantStatus?.map((option, index) => (
                            <Dropdown.Item href="#" value={option.id} onClick={() => handleStatus(item.ApplicationId, option.value)}>
                              {option.value}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    ):(
                      <Button variant="danger" className="fs-12" onClick={() => handleStatus(item.ApplicationId, "Pending For Call")}>
                      Acknowledge
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
