import { GET_PDF_LIST_SUCCESS } from "../actions/askAllenChat";

const initialState = {
    PDFList: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PDF_LIST_SUCCESS:
            return { ...state, PDFList: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;