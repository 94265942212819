import { combineReducers } from 'redux';
import loader from './loader'
import jobOpening from '../reducers/jobOpening';
import candidate from '../reducers/candidate';
import askAllenChat from '../reducers/askAllenChat';

export default combineReducers({
    loader,
    jobOpening,
    candidate,
    askAllenChat
    
})