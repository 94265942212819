import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
// import userAvatar from "../assets/img/img1.jpg";
import { Button } from "react-bootstrap";
import axios from "axios";
import ToastifyService from "../View/_common/ToastifyService";
import AuthContext from "../core/AuthContext";
import Environment from "../core/Environment";
//import notification from "../data/Notification";
import { useDispatch } from "react-redux";
import { GetPDFList } from "../application/actions/askAllenChat";

export default function Header({ onSkin }) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("jwtDetails"));

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem("skin-mode", skin);

      //onSkin("dark");
    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem("skin-mode");

      //onSkin("");
    }
  };

  const { token, logout } = useContext(AuthContext);
  let userDetails = JSON.parse(localStorage.getItem("jwtDetails"));
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const uploadPdf = async (files) => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("jwtDetails"));

    if (!user) {
      console.error("Token or user details not found in localStorage");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    formData.append("clientId", user.clientId);
    formData.append("UserId", user.id); // Assuming user.id represents the UserId in your system

    try {
      await axios.post(
        `${Environment.ASK_ALLEN_URL}upload_pdf_to_mssql`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      ToastifyService.success(`Resume Uploaded Successfully!`);
      loadAllPdfs(); // Assuming loadAllPdfs() is a function to refresh the list of uploaded PDFs
    } catch (error) {
      console.error("Error uploading file:", error);
      ToastifyService.error("Error uploading file. Please try again.");
    }
  };

  const loadAllPdfs =() => {
   dispatch(GetPDFList(user.clientId))
  };

  // const sidebarSkin = (e) => {
  //   e.preventDefault();
  //   e.target.classList.add("active");

  //   let node = e.target.parentNode.firstChild;
  //   while (node) {
  //     if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
  //       node.classList.remove("active");
  //     node = node.nextElementSibling || node.nextSibling;
  //   }

  //   let skin = e.target.textContent.toLowerCase();
  //   let HTMLTag = document.querySelector("html");

  //   HTMLTag.removeAttribute("data-sidebar");

  //   if (skin !== "default") {
  //     HTMLTag.setAttribute("data-sidebar", skin);
  //     localStorage.setItem("sidebar-skin", skin);
  //   } else {
  //     localStorage.removeItem("sidebar-skin", skin);
  //   }
  // };

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>

      {/* UPLOAD RESUME */}
      <div className="me-auto">
        <input
          type="file"
          className="form-control visually-hidden"
          multiple
          ref={fileInputRef}
          onChange={(e) => uploadPdf(e.target.files)}
        />
        <Button variant="dark" onClick={handleButtonClick}>
          Upload Resumes
        </Button>
        {/* <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i> */}
      </div>

      {/* SKIN MODE */}
      <Dropdown className="dropdown-skin ms-3" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Skin Mode</label>
          <nav className="nav nav-skin">
            <Link
              onClick={skinMode}
              className={
                localStorage.getItem("skin-mode")
                  ? "nav-link"
                  : "nav-link active"
              }
            >
              Light
            </Link>
            <Link
              onClick={skinMode}
              className={
                localStorage.getItem("skin-mode")
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Dark
            </Link>
          </nav>
          {/* <hr /> */}
          {/* <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link onClick={sidebarSkin} className={!localStorage.getItem("sidebar-skin") ? "nav-link active" : "nav-link"}>Default</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "prime") ? "nav-link active" : "nav-link"}>Prime</Link>
            <Link onClick={sidebarSkin} className={localStorage.getItem("sidebar-skin") === "dark" ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav> */}
        </Dropdown.Menu>
      </Dropdown>

      {/* USER DROP DOWN */}
      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <div className="user-profile">
              <i className="ri-user-fill fs-20"></i>
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <div className="user-profile">
                <i className="ri-user-fill fs-36"></i>
              </div>
            </div>
            <h5 className="mb-1 text-dark fw-semibold">
              {userDetails?.username}
            </h5>
            <p className="fs-sm text-secondary">Premium Member</p>

            {/* <nav className="nav">
              <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
              <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
            </nav>
            <hr /> */}
            <nav className="nav">
              {/* <Link to=""><i className="ri-question-line"></i> Help Center</Link>
              <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
              <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link> */}
              <Link
                to="/account/login"
                onClick={() => {
                  logout();
                }}
              >
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
