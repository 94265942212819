import React, { useContext, useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import Environment from "../../core/Environment";
import empType from "../../json/empType.json";
import Editor from "react-simple-wysiwyg";
import AddRoleCategory from "./add-role-category";
import AuthContext from "../../core/AuthContext";
import { AddJob } from "./../../application/actions/JobOpeningAction";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../_common/LoadingSpinner";

export default function PostJobModal(props) {
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const [loading, setLoading]= useState(false)
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [roleCatList, setRoleCatList] = useState([]);
  useEffect(() => {
    loadAllRoleCategory();
  }, []);
  const initialState = {
    FunctionalArea: "",
    clientId: JSON.parse(localStorage.getItem("jwtDetails")).clientId,
    RoleCategory: "Software",
    JobTitle: "",
    Location: "",
    JobStatus: 1,
    AboutJob: "",
    NoOfPositions: "",
    MiniQualification: "",
    ReqQualification: "",
    PrefferredLang: "",
    EmpType: "FullTime",
    PostedBy: "",
    ModifiedBy: "",
    RequiredSkills: "",
    KeyResponsibilities: "",
    ExperienceReq: "",
    JobSummary: "",
    AdditionalInfo: "",
  };
  const [formData, setFormData] = useState(initialState);

  const handleClose = () => {
    props.closeFunction(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const loadAllRoleCategory = async () => {
    const user = JSON.parse(localStorage.getItem("jwtDetails"));

    try {
      const response = await axios.get(
        `${Environment.ASK_ALLEN_URL}getRoleCatByClientIdEndPoint`,
        {
          params: {
            clientId: user.clientId, // Send clientId as query parameter
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setRoleCatList(response.data); // Ensure this matches the API response structure
    } catch (error) {
      console.error("Error loading job posts:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      dispatch(AddJob(formData));
      setFormData(initialState);
      setLoading(false)
      handleClose();
    } catch (error) {
      setLoading(false)
      console.error("Error posting job:", error);
      // Handle error (e.g., show an error message)
    }
  };
  const handleRoleList = (data) => {
    setRoleCatList(data);
  };
  return (
    <React.Fragment>
      <AddRoleCategory
        show={showRoleModal}
        closeModal={() => setShowRoleModal(false)}
        RoleCatList={handleRoleList}
        roleCategoryList={roleCatList}
      />
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Post Job
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          {/* <Form onSubmit={handleSubmit}> */}
          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Role/Title: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="JobTitle"
                  placeholder="Enter Role/Title"
                  value={formData.JobTitle}
                  onChange={handleChange}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Functional Area:</Form.Label>
                <Form.Control
                  type="text"
                  name="FunctionalArea"
                  placeholder="Enter Functional Area"
                  value={formData.FunctionalArea}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Role Category: <sup className="text-danger">*</sup>
                </Form.Label>
                <div className="d-flex align-items-center gap-3">
                  <Form.Select
                    name="RoleCategory"
                    value={formData.RoleCategory}
                    onChange={handleChange}
                    required
                  >
                    <option>Choose Role Category</option>
                    {roleCatList?.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.RoleCategoryName}
                      </option>
                    ))}{" "}
                  </Form.Select>
                  <Button
                    variant="dark"
                    className="btn-icon"
                    onClick={() => {
                      setShowRoleModal(true);
                    }}
                  >
                    <i className="ri-add-line"></i>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Employment Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  name="EmpType"
                  value={formData.EmpType}
                  onChange={handleChange}
                  required
                >
                  <option>Choose Employment Type</option>
                  {empType?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.value}
                    </option>
                  ))}
                  {/* Add more options here */}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Location: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="Location"
                  placeholder="Enter Location"
                  value={formData.Location}
                  onChange={handleChange}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Required Experience: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="ExperienceReq"
                  placeholder="Enter Experience Required"
                  value={formData.ExperienceReq}
                  onChange={handleChange}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  No of Positions: 
                </Form.Label>
                <Form.Control
                  type="text"
                  name="NoOfPositions"
                  placeholder="Enter No Of Positions"
                  value={formData.NoOfPositions}
                  onChange={handleChange}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Job Summary:</Form.Label>
                <Editor
                  type="text"
                  name="JobSummary"
                  placeholder="Enter Job Summary"
                  value={formData.JobSummary}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={10} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Preferred Language:</Form.Label>
                <Form.Control
                  type="text"
                  name="PrefferredLang"
                  placeholder="Enter Preferred Language"
                  value={formData.PrefferredLang}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Min Qualification:</Form.Label>
                <Editor
                  name="MiniQualification"
                  placeholder="Enter Min Qualification"
                  value={formData.MiniQualification}
                  onChange={handleChange}
                ></Editor>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Required Qualification: <sup className="text-danger">*</sup>
                </Form.Label>
                <Editor
                  name="ReqQualification"
                  placeholder="Enter Required Qualification"
                  value={formData.ReqQualification}
                  onChange={handleChange}
                ></Editor>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Key Responsibilities: <sup className="text-danger">*</sup>
                </Form.Label>
                <Editor
                  name="KeyResponsibilities"
                  placeholder="Enter Key Responsibilities"
                  value={formData.KeyResponsibilities}
                  onChange={handleChange}
                ></Editor>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Required Skills: <sup className="text-danger">*</sup>
                </Form.Label>
                <Editor
                  name="RequiredSkills"
                  placeholder="Enter Required Skills"
                  value={formData.RequiredSkills}
                  onChange={handleChange}
                ></Editor>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Additional Information:</Form.Label>
                <Editor
                  name="AdditionalInfo"
                  placeholder="Enter Additional Information"
                  value={formData.AdditionalInfo}
                  onChange={handleChange}
                ></Editor>
              </div>
            </Col>
          </Row>

          {/* </Form> */}
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
        <Button   type="button"
            variant="primary"
            onClick={handleSubmit}
            className="fs-14 me-2 d-flex align-items-center">
                  {loading ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    <>
                     <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                     <span className="align-middle">POST</span></>
                  )}
                </Button>
          {/* <Button
            type="button"
            variant="primary"
            onClick={handleSubmit}
            className="fs-14 me-2 d-flex align-items-center"
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
            <span className="align-middle">POST</span>
          </Button> */}
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
