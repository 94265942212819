
const dashboardMenu = [
  {
    "label": "Dashboard",
    "link": "/dashboard/manage",
    "icon": "ri-pie-chart-2-line"
  },
  {
    "label": "Ask Allen",
    "link": "/askHRVi/manage",
    "icon": "ri-questionnaire-line",
    "count": "Beta"
  },
  {
    "label": "Job Openings",
    "link": "/jobOpening/manage",
    "icon": "ri-search-2-line"
  },
  {
    "label": "Candidates",
    "link": "/candidates/manage",
    "icon": "ri-user-2-line"
  },
]

export { dashboardMenu };