import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";

import {
    GET_CANDIDATES_LIST,
  ADD_CANDIDATE,
  DELETE_CANDIDATE,
  GetCandidateSuccess,
  UPDATE_CANDIDATE,
  GET_CANDIDATE_DETAILS,
  CandidateDetailsSuccess,
} from "../actions/candidateAction";

const GetCandidateList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CANDIDATES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetCandidateList" }));
        const candidateList = await api.candidateAPI.GetCandidateList(action.payload);
        dispatch(GetCandidateSuccess(candidateList));
        dispatch(Loading.setLoading({ loading: false, value: "GetCandidateList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddNewCandidate =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_CANDIDATE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetCandidateOpenningList" }));
        const candidateList = await api.candidateAPI.AddNewCandidate(action.payload);
        dispatch(GetCandidateSuccess(candidateList));
        dispatch(Loading.setLoading({ loading: false, value: "GetCandidateOpenningList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UpdateCandidate =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CANDIDATE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetCandidateOpenningList" }));
        const candidateList = await api.candidateAPI.UpdateCandidate(action.payload);
        dispatch(GetCandidateSuccess(candidateList));
        dispatch(Loading.setLoading({ loading: false, value: "GetCandidateOpenningList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const DeleteCandidate =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_CANDIDATE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetCandidateOpenningList" }));
        const candidateList = await api.candidateAPI.DeleteCandidate(action.payload);
        dispatch(GetCandidateSuccess(candidateList));
        dispatch(Loading.setLoading({ loading: false, value: "GetCandidateOpenningList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const CandidateDetails =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CANDIDATE_DETAILS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "CandidateDetails" }));
        const CandidateDetails = await api.candidateAPI.CandidateDetails(action.payload);
        dispatch(CandidateDetailsSuccess(CandidateDetails));
        dispatch(Loading.setLoading({ loading: false, value: "CandidateDetails" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
    GetCandidateList,AddNewCandidate,UpdateCandidate,DeleteCandidate,CandidateDetails
];
