import { GET_CANDIDATE_DETAILS_SUCCESS, GET_CANDIDATE_LIST_SUCCESS } from "../actions/candidateAction";

const initialState = {
    CandidateList: undefined,
    CandidateDetails: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CANDIDATE_LIST_SUCCESS:
            return { ...state, CandidateList: action.payload.data, error: null };
        case GET_CANDIDATE_DETAILS_SUCCESS:
            return { ...state, CandidateDetails: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;