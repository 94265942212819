import Environment from "../../../core/Environment";
import jwtInterceptor from "./../../../core/helpers/jwtInterceptor";

const GetJobOpenningList = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.ASK_ALLEN_URL}getJobListByClientId?clientId=${id}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const AddNewJob = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.ASK_ALLEN_URL}postJob`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UpdateJob = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.ASK_ALLEN_URL}updateJob`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const DeleteJob = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.ASK_ALLEN_URL}deleteJob/${data.id}/${data.clientId}`,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetJobDetails = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.ASK_ALLEN_URL}getJobDetailswithListById?jobId=${id}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UpdateStatus = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.ASK_ALLEN_URL}updateApplicantStatus`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
export default {
    GetJobOpenningList,
    AddNewJob,
    UpdateJob,
    DeleteJob,
    GetJobDetails,
    UpdateStatus
};