import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";

import {
  GET_JOB_OPENNING_LIST,
  ADD_JOB,
  DELETE_JOB,
  GetJobOpenningSuccess,
  UPDATE_JOB,
  jobDetailsSuccess,
  GET_JOB_DETAILS,
  ApplicantListSuccess,
  UPDATE_STATUS,
} from "../actions/JobOpeningAction";

const GetJobOpenningList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_JOB_OPENNING_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetJobOpenningList" }));
        const jobOpeningList = await api.jobOpenningAPI.GetJobOpenningList(action.payload);
        dispatch(GetJobOpenningSuccess(jobOpeningList.data));
        dispatch(Loading.setLoading({ loading: false, value: "GetJobOpenningList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddNewJob =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_JOB) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetJobOpenningList" }));
        const jobOpeningList = await api.jobOpenningAPI.AddNewJob(action.payload);
        dispatch(GetJobOpenningSuccess(jobOpeningList.data));
        dispatch(Loading.setLoading({ loading: false, value: "GetJobOpenningList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UpdateJob =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_JOB) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetJobOpenningList" }));
        const jobOpeningList = await api.jobOpenningAPI.UpdateJob(action.payload);
        dispatch(GetJobOpenningSuccess(jobOpeningList.data));
        dispatch(Loading.setLoading({ loading: false, value: "GetJobOpenningList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const DeleteJob =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_JOB) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GetJobOpenningList" }));
        const jobOpeningList = await api.jobOpenningAPI.DeleteJob(action.payload);
        
        dispatch(GetJobOpenningSuccess(jobOpeningList.data));
        dispatch(Loading.setLoading({ loading: false, value: "GetJobOpenningList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetJobDetails =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_JOB_DETAILS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "jobDetails" }));
        const jobDetails = await api.jobOpenningAPI.GetJobDetails(action.payload);
        console.log("jobOpeningList",jobDetails)
        dispatch(jobDetailsSuccess(jobDetails));
        
        dispatch(ApplicantListSuccess(jobDetails.data.applicantList));
        dispatch(Loading.setLoading({ loading: false, value: "jobDetails" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UpdateStatus =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_STATUS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "CandidateList" }));
        const CandidateList = await api.jobOpenningAPI.UpdateStatus(action.payload);
        console.log("CandidateList",CandidateList)
        dispatch(ApplicantListSuccess(CandidateList.data.applicantList));
        dispatch(GetJobOpenningSuccess(CandidateList.data.jobData));
        dispatch(Loading.setLoading({ loading: false, value: "CandidateList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
    GetJobOpenningList,AddNewJob,UpdateJob,DeleteJob,GetJobDetails,UpdateStatus
];
