import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import Environment from "../../core/Environment";
import LoadingSpinner from "../_common/LoadingSpinner";

function AddRoleCategory(props) {
  const [formData, setFormData] = useState({ RoleCategoryName: "" });
  const [loading, setLoading] = useState(false);
  const [existError, setExistError] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (existError) return;
    setLoading(true);
    try {
      const response = await axios.post(
        `${Environment.ASK_ALLEN_URL}addRoleCategory`,
        {
          ...formData,
          clientId: JSON.parse(localStorage.getItem("jwtDetails")).clientId,
        },
        {
          headers: {
            authorization: `Bearer ${props.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Call Joblist function to refresh the job list in the parent component
      props.RoleCatList(response.data);
      setLoading(false);
      // Handle success (e.g., show a success message)
      handleClose();
    } catch (error) {
      setLoading(false);
      console.error("Error adding role category:", error);
      // Handle error (e.g., show an error message)
    }
  };
  const handleClose = () => {
    props.closeModal();
  };
  const ifRoleCategoryExist = (e) => {
    const exist = props.roleCategoryList.some(
      (role) => role.RoleCategoryName === e.target.value
    );
    exist ? setExistError(true) : setExistError(false);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add Role Category
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl={10} md={10} sm={12}>
                <div className="mb-4">
                  <Form.Label>
                    Role Category: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="RoleCategoryName"
                    placeholder="Enter Role Category"
                    value={formData.RoleCategoryName}
                    onChange={ifRoleCategoryExist}
                    required
                  />
                {existError ? <Form.Text className="text-danger">Role Category already exist</Form.Text> : ""}
                </div>
              </Col>
            </Row>

            <div className="offcanvas-footer justify-content-start">
              <Button
                type="submit"
                variant="primary"
                className="fs-14 me-2 d-flex align-items-center"
                disabled={loading}
              >
                {loading ? (
                  <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                ) : (
                  <>
                    <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                    <span className="align-middle">ADD</span>
                  </>
                )}
              </Button>
              {/* <Button
                type="submit"
                variant="primary"
                className="fs-14 me-2 d-flex align-items-center"
              >
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                <span className="align-middle">ADD</span>
              </Button> */}
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}

export default AddRoleCategory;
