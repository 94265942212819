import React, { useEffect, useState } from "react";
import { Offcanvas, Tabs, Tab } from "react-bootstrap";
import CandidatesDetails from "./candidate-details";
import JobList from "./joblist";
import { useDispatch } from "react-redux";
import { jobOpenDetails } from "../../../application/actions/JobOpeningAction";

export default function UpdateCandidates(props) {
  const handleClose = () => {
    props.closeFunction(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const [activeTab, setActiveTab] = useState("candidate_details_tab");
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Update Candidate
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="common-tabs">
                        <Tab eventKey="candidate_details_tab" title={<><h6 className="fs-14 mb-0">Candidate Details</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3 pb-5">
                               <CandidatesDetails candidateDetail={props.candidateDetail} />
                            </div>
                        </Tab>
                        <Tab eventKey="edit_details_tab" title={<><h6 className="fs-14 mb-0">Jobs List</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3 pb-5">
                                <JobList />
                            </div>
                        </Tab>
                    </Tabs> */}
          <div className="custom-tab-body pt-3 pb-5">
            <CandidatesDetails candidateDetail={props.candidateDetail} />
          </div>
          {/* </Form> */}
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
