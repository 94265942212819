import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { Provider } from "react-redux";
import { configureStore } from "./application/store";
import services from './infrastructure/services';

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import { AuthContextProvider } from "./core/AuthContext";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
      <AuthContextProvider>
      <Provider store={configureStore(services)}>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
        </Provider>
        </AuthContextProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}
