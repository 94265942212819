import React, { useContext, useEffect, useRef, useState } from "react";
import "./ask-hrvi.scss"
import Header from "../../layouts/Header";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Image,
  Dropdown,
  Table,
  ListGroup
} from "react-bootstrap";
import axios from "axios";
import Avatar from "../../components/Avatar";
import mainLogo from "../../assets/img/logo/logo-icon.png";
import chatGIF from "../../assets/img/logo/Allen_Loader.gif";
import PerfectScrollbar from "perfect-scrollbar";
import AuthContext from "../../core/AuthContext";
import Environment from "../../core/Environment";
import getStart from "../../assets/img/loader/get-start.gif";
import { useDispatch } from "react-redux";
import { GetPDFList } from "../../application/actions/askAllenChat";

export default function AskHRVi() {
  const { token } = useContext(AuthContext);
  const [question, setQuestion] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const chatBodyRef = useRef(null);
  const [msgShow, setMsgShow] = useState(false);
  const [pinProfile, setPinProfile] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const navigate = useNavigate()
  const [editQuestion, setEditQuestion] = useState("");
  const user = JSON.parse(localStorage.getItem("jwtDetails"));

  // Authentication check
  const [authenticated, setAuthenticated] = useState(true); // Change this to false initially
const dispatch= useDispatch()
  function handlePinClick(cell) {
    const anchorElement = document.createElement("div");
    anchorElement.innerHTML = cell;
    const anchorTag = anchorElement.querySelector("a");
    if (anchorTag) {
      const link = anchorTag.getAttribute("href");
      const fileName = link.substring(link.lastIndexOf("/") + 1);
      setPinProfile([...pinProfile, { name: fileName, link }]);
    }
  }

  useEffect(() => {
    if (chatBodyRef.current) {
      new PerfectScrollbar(chatBodyRef.current);
    }
  }, [chatBodyRef]);

  const navToggle = (e) => {
    e.target.closest(".row").classList.toggle("nav-show");
  };

  const askQuestion = async () => {
    if (!question.trim()) return; // Prevent empty questions

    const newConversationHistory = [
      ...conversationHistory,
      { question: question, response: null, loading: true },
    ];

    setConversationHistory(newConversationHistory);
    setQuestion("");

    try {
      const response = await axios.post(
        `${Environment.ASK_ALLEN_URL}ask_question`,
        {
          question,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );


      const updatedConversationHistory = newConversationHistory.map(
        (item, index) => {
          if (index === newConversationHistory.length - 1) {
            return {
              question: item.question,
              response: response.data.response,
              loading: false,
            };
          }
          return item;
        }
      );

      setConversationHistory(updatedConversationHistory);
    } catch (error) {
      console.error("Error asking question:", error);
      const updatedConversationHistory = newConversationHistory.map(
        (item, index) => {
          if (index === newConversationHistory.length - 1) {
            return {
              question: item.question,
              response: "Error loading response.",
              loading: false,
            };
          }
          return item;
        }
      );

      setConversationHistory(updatedConversationHistory);
    } finally {
    }
  };


  useEffect(() => {
    dispatch(GetPDFList(user?.clientId))

  }, [token]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      askQuestion();
    }
  };
  const quickPrompts = async (e, question) => {
    e.preventDefault();
    if (!question.trim()) return; // Prevent empty questions

    const newConversationHistory = [
      ...conversationHistory,
      { question: question, response: null, loading: true },
    ];

    setConversationHistory(newConversationHistory);
    setQuestion("");

    try {
      const response = await axios.post(
        `${Environment.ASK_ALLEN_URL}ask_question`,
        {
          question,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedConversationHistory = newConversationHistory.map(
        (item, index) => {
          if (index === newConversationHistory.length - 1) {
            return {
              question: item.question,
              response: response.data.response,
              loading: false,
            };
          }
          return item;
        }
      );

      setConversationHistory(updatedConversationHistory);
    } catch (error) {
      console.error("Error asking question:", error);
    }
  };

  const removeProfile = (indexToRemove) => {
    setPinProfile((prevPinProfile) =>
      prevPinProfile.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditQuestion(conversationHistory[index].question);
  };
  const handleSaveEdit = async (index) => {
    const updatedConversationHistory = [...conversationHistory];
    updatedConversationHistory[index] = {
      ...updatedConversationHistory[index],
      question: editQuestion,
      response: null,
      loading: true,
    };
    setConversationHistory(updatedConversationHistory);
    setEditIndex(null);

    try {
      const response = await axios.post(
        `${Environment.ASK_ALLEN_URL}ask_question`,
        {
          question: editQuestion,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setConversationHistory((prevConversationHistory) =>
        prevConversationHistory.map((item, i) =>
          i === index
            ? { ...item, response: response.data.response, loading: false }
            : item
        )
      );
    } catch (error) {
      console.error("Error asking question:", error);

      setConversationHistory((prevConversationHistory) =>
        prevConversationHistory.map((item, i) =>
          i === index
            ? { ...item, response: "Error loading response.", loading: false }
            : item
        )
      );
    }
  };
  return (
    <React.Fragment>
      {/* {!authenticated && <Navigate to="/account/signin" />} Redirect if not authenticated */}

      <Header />
      {/* <div className="main main-app ask-chat-main ps-3"> */}
      {/* <Row className="m-0"> */}
      {/* <Col md={9}>
            <div className={"chat-panel mt-3" + (msgShow ? " msg-show" : "")}> */}
      <div className="main main-app ask-chat-main p-3 p-lg-4">
        <Row className="m-0">

          {/* CHAT CARD */}
          <Col md={9}>
            <div className={"chat-panel" + (msgShow ? " msg-show" : "")}>
              <div className="chat-body m-0">
                <div ref={chatBodyRef} className="chat-body-content">
                  {conversationHistory.length === 0 && (
                    <div className="get-start-loader">
                        <Image src={getStart} />

                        <h5>Hi There...</h5>
                    </div>
                  )}
                  {conversationHistory.map((item, ind) => (
                    <React.Fragment key={ind}>
                      <div className="msg-item reverse">
                        {/* <Avatar img={userAvatar}/> */}
                        <div className="msg-body">
                          <div className="msg-bubble-row">
                            <div className="nav-icon me-2">
                              <Link
                                onClick={() => handleEditClick(ind)}
                                className="nav-link"
                              >
                                <i className="ri-pencil-fill"></i>
                              </Link>
                            </div>
                            {editIndex === ind ? (
                              <>
                                <Form.Control
                                  type="text"
                                  value={editQuestion}
                                  onChange={(e) =>
                                    setEditQuestion(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();

                                      handleSaveEdit(ind);
                                    }
                                  }}
                                />

                                <Button
                                  variant="primary"
                                  onClick={() => handleSaveEdit(ind)}
                                >
                                  Save
                                </Button>
                              </>
                            ) : (
                              <div className="msg-bubble">{item.question}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="msg-item ">

                        <div className="msg-body">
                          <Row className="gx-3 row-cols-auto">
                            <Col>
                              <div
                                className="msg-bubble-answer d-flex align-items-start"
                                onMouseOver={navToggle}
                                onMouseLeave={navToggle}
                              >
                                {item.loading ? (
                                  <div className="loader">
                                    <img src={chatGIF} className="wt-50" alt="Loading..." />
                                  </div>
                                ) : (

                                  <>
                                    <Avatar img={mainLogo} />
                                    <Table bordered>
                                      {item.response
                                        .replace(/\*/g, "")
                                        .replace(/---/g, "")
                                        .trim()
                                        .split(/\n+/)
                                        .map((line, lineIndex) => {
                                          const cells = line
                                            .split("|")
                                            .map((cell) => cell.trim())
                                            .filter((cell) => cell !== "");

                                          if (lineIndex === 0) {
                                            // Skip the header row
                                            return (
                                              <tbody key={lineIndex}>
                                                <tr>
                                                  {cells.map(
                                                    (cell, cellIndex) => (
                                                      <th key={cellIndex}>
                                                        <div className="d-flex">
                                                          <span
                                                            dangerouslySetInnerHTML={{
                                                              __html: cell,
                                                            }}
                                                          ></span>
                                                        </div>
                                                      </th>
                                                    )
                                                  )}
                                                </tr>
                                              </tbody>
                                            );
                                          } else {
                                            return (
                                              <tbody key={lineIndex}>
                                                <tr>
                                                  {cells.map(
                                                    (cell, cellIndex) => (
                                                      <React.Fragment
                                                        key={cellIndex}
                                                      >
                                                        <td
                                                          style={{
                                                            color: "black",
                                                          }}
                                                          key={cellIndex}
                                                        >
                                                          <div className="d-flex">
                                                            {cell.includes(
                                                              "<a"
                                                            ) && (
                                                                <Dropdown className="chat-option-dropdown ms-2 pe-3">
                                                                  <Dropdown.Toggle
                                                                    variant="default"
                                                                    id="dropdown-basic"
                                                                  >
                                                                    <i className="ri-more-2-fill fs-16"></i>
                                                                  </Dropdown.Toggle>
                                                                  <Dropdown.Menu className="p-0">
                                                                    <Dropdown.Item
                                                                      onClick={() =>
                                                                        handlePinClick(
                                                                          cell
                                                                        )
                                                                      }
                                                                    >
                                                                      Pin This
                                                                      Candidate
                                                                    </Dropdown.Item>
                                                                  </Dropdown.Menu>
                                                                </Dropdown>
                                                              )}
                                                            <span
                                                              dangerouslySetInnerHTML={{
                                                                __html: cell,
                                                              }}
                                                            ></span>
                                                          </div>
                                                        </td>
                                                      </React.Fragment>
                                                    )
                                                  )}
                                                </tr>
                                              </tbody>
                                            );
                                          }
                                        })}
                                    </Table>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>

                <div className="chat-body-footer pt-3 ht-auto">
                  <div className="msg-box">
                    <Form.Control
                      type="text"
                      as="textarea"
                      rows={2}
                      placeholder="Ask Allen your query.."
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />

                    <Link to="" className="msg-send" onClick={askQuestion}>
                      <i className="ri-send-plane-2-line"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <Row>
              <Col md={12}>
                <Card className="card-one mb-2 h-auto">
                  <Card.Body>
                    <div className="sidebar-right w-100">
                      <h5 className="section-title mb-3">
                        <span>Pinned Profiles</span>
                      </h5>


                      {pinProfile.length === 0 && (
                        <span className="ps-2">No Profiles Pinned Yet!</span>
                      )}
                      {pinProfile?.map((menu, index) => (
                        <ListGroup as="ul" className="list-group-one" key={index}>
                          <ListGroup.Item key={index} as="li" className="px-0 d-flex align-items-center gap-2 justify-content-between">
                            <div className="d-flex align-items-center ga-1">
                              <div className="avatar">
                                <i className="ri-user-line me-1 fs-14 text-dark"></i>
                              </div>
                              <div><h6 className="mb-0 fs-12 text-dark">{menu.name}</h6></div>
                            </div>
                            <div className="text-right">
                              <a href={menu.link} rel="noreferrer" target="_blank" className="text-dark" >
                                <i className="ri-eye-line"></i>
                              </a>
                              <span onClick={() => removeProfile(index)} className="text-dark ps-2 cursor-pointer">
                                <i className="ri-pushpin-line"></i>
                              </span>
                            </div>
                          </ListGroup.Item>
                        </ListGroup>


                      ))}

                      {/* {pinProfile.length === 0 && (
                    <span className="ps-2">No Profiles Pinned Yet!</span>
                  )}
                  <Table>
                    <tbody>
                      {pinProfile?.map((menu, index) => (
                        <tr>
                          <td className="ps-0 pe-0">
                            <div className="d-flex align-items-center ">
                              <i className="ri-user-line me-1"></i>
                              <span className="ps-2" key={index}>
                                {menu.name}
                              </span>
                            </div>
                          </td>
                          <td className="pe-0">
                            <a
                              href={menu.link}
                              rel="noreferrer"
                              target="_blank"
                              className="text-dark"
                            >
                              <i className="ri-eye-line"></i>
                            </a>
                            <span
                              onClick={() => removeProfile(index)}
                              className="text-dark ps-2"
                            >
                              <i className="ri-pushpin-2-line"></i>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table> */}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card className="card-one mt-3 h-auto">
                  <Card.Body>
                    <h5 className="section-title mb-3 d-flex justify-content-between align-items-center">
                      Quick Prompts
                      <i className="ri-add-line"></i>
                    </h5>
                    {[
                      {
                        title: "Healthcare experience",
                        desc: "give me list of individuals who have healthcare experience",
                      },
                      {
                        title: "Bachelor's in finance",
                        desc: "give me list of individuals who have done bachelor's in finance",
                      },
                      {
                        title: "People in New York",
                        desc: "give me list of individuals who live New York",
                      },
                      {
                        title: "English communications skills",
                        desc: "give me list in table of individuals who have Very good English communications skills ",
                      },
                      {
                        title: "Experience Based On Years",
                        desc: "give me list of individuals who have more than 4 years of sales experience ",
                      },
                    ].map((podcast, index) => (
                      <div key={index} className="media-music">
                        <div className="media-body">
                          <h6>
                            <Link onClick={(e) => quickPrompts(e, podcast.desc)}>
                              {podcast.title}
                            </Link>
                          </h6>
                          <span>{podcast.desc}</span>
                        </div>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          {/* <Col>
            <Card className="card-one">
              <Card.Header className="ht-50">
                <h3 className="fs-14 mb-0">Pinned Profiles</h3>
              </Card.Header>
              <Card.Body>
                <Table className="table mb-0" responsive>
                  <thead>
                    <tr>
                      <th className="pt-2 pb-2 w-60">Name</th>
                      <th className="pt-2 pb-2">Download Link</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Marie</td>
                      <td>
                        <Link><i className="ri-links-line fs-16"></i></Link>
                      </td>
                    </tr>

                    <tr>
                      <td>Isadora</td>
                      <td>
                        <Link><i className="ri-links-line fs-16"></i></Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="card-one">
              <Card.Header className="justify-content-between ht-50">
                <h3 className="fs-14 mb-0">Favourite Prompts</h3>
                <Button variant="primary" className="d-flex align-items-center gap-1">
                  <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline"> ADD</span>
                </Button>
              </Card.Header>
              <Card.Body>
                <Table className="table mb-0" responsive>
                  <thead>
                    <tr>
                      <th className="pt-2 pb-2 w-60">Prompts Name</th>
                      <th className="pt-2 pb-2">Search</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Marie</td>
                      <td>
                        <Link><i className="ri-search-line fs-16"></i></Link>
                      </td>
                    </tr>

                    <tr>
                      <td>Isadora</td>
                      <td>
                        <Link><i className="ri-search-line fs-16"></i></Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </div>
    </React.Fragment>
  );
}
