import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../core/AuthContext';

const PrivateRoute = ({ element: Component }) => {
  const { token } = useContext(AuthContext);
  return token ? Component : <Navigate to="/account/login" />;
};

export default PrivateRoute;
