import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCandidateDetails } from "../../../application/selectors/indexSelector";

export default function CandidatesDetails() {
  const user = JSON.parse(localStorage.getItem("jwtDetails"));
  const candidateDetail = useSelector(getCandidateDetails);
  console.log(candidateDetail);

  const [formData, setFormData] = useState({
    candidateId: "",
    candidateName: "",
    emailId: "",
    phoneNumber: "",
    locationName: "",
    Experience: "",
    Education: "",
    Skills: "",
    tags: "",
    Gender: "",
    DOB: "",
    About: "",
    clientId: user.clientId,
    resume_url: "",

    // For storing the selected file
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    if (candidateDetail) {
      setFormData({
        ...formData,
        candidateId: candidateDetail.candidateId,
        candidateName: candidateDetail.candidateName,
        emailId: candidateDetail.emailId,
        phoneNumber: candidateDetail.phoneNumber,
        locationName: candidateDetail.locationName,
        Experience: candidateDetail.Experience,
        Education: candidateDetail.Education,
        Skills: candidateDetail.Skills,
        tags: candidateDetail.tags,
        Gender: candidateDetail.Gender,
        DOB: formatDate(candidateDetail.DOB),
        About: candidateDetail.About,
        clientId: user.clientId,
        ResumeFile: candidateDetail.ResumeFile,
        // For storing the selected file
      });
    }
  }, [candidateDetail]);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="candidateName"
              value={formData.candidateName}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email ID"
              name="emailId"
              value={formData.emailId}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Location"
              name="locationName"
              value={formData.locationName}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>Experience</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Experience"
              name="Experience"
              value={formData.Experience}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>Education</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Education"
              name="Education"
              value={formData.Education}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>

      {/* <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Skills</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Skills"
                    name="Skills"
                    value={formData.Skills}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Tags"
                    name="tags"
                    value={formData.tags}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row> */}

      <Row>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              name="Gender"
              value={formData.Gender}
              onChange={handleChange}
              required
            >
              <option>Choose Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Select>
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div className="mb-4">
            <Form.Label>DOB</Form.Label>
            <Form.Control
              type="date"
              name="DOB"
              value={formData.DOB}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <div className="mb-4">
            <Form.Label>About</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter About"
              name="About"
              value={formData.About}
              onChange={handleChange}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <div className="mb-4">
            <Form.Label>Upload Resume</Form.Label>
            {formData.ResumeFile !== "" && (
              <div className="text-right mb-2">
                Preview Uploaded File
                <a
                  href={formData.ResumeFile}
                  rel="noreferrer"
                  target="_blank"
                  className="text-dark px-2"
                >
                  <i className="ri-eye-line"></i>
                </a>
              </div>
            )}
            {/* <p>{formData.ResumeFile}</p> */}
            <Form.Control type="file" onChange={handleFileChange} required />
          </div>
        </Col>
      </Row>
      {/* <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="candidateName"
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email ID"
                    name="emailId"
                    required
                  />
                </div>
              </Col>
            </Row>


            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Number"
                    name="phoneNumber"
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Location"
                    name="locationName"
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Experience</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Experience"
                    name="Experience"
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Education</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Education"
                    name="Education"
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Skills</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Skills"
                    name="Skills"
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Tags"
                    name="tags"
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    name="Gender"
                    required
                  >
                    <option>Choose Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="mb-4">
                  <Form.Label>DOB</Form.Label>
                  <Form.Control
                    type="date"
                    name="DOB"
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mb-4">
                  <Form.Label>About</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter About"
                    name="About"
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="mb-4">
                  <Form.Label>Upload Resume</Form.Label>
                  <Form.Control type="file" required />
                </div>
              </Col>
            </Row> */}
    </React.Fragment>
  );
}
