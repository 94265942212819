export const GET_JOB_OPENNING_LIST = 'GET_JOB_OPENNING_LIST';
export const GET_JOB_DETAILS = 'GET_JOB_DETAILS';
export const ADD_JOB = 'ADD_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const GET_JOB_OPENNING_LIST_SUCCESS = 'GET_JOB_OPENNING_LIST_SUCCESS';
export const GET_JOB_DETAILS_SUCCESS = 'GET_JOB_DETAILS_SUCCESS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const APPLICANT_LIST_SUCCESS = 'APPLICANT_LIST_SUCCESS';

export const jobOpeningList = (id) => ({
    type: GET_JOB_OPENNING_LIST,
    payload:id
});
export const AddJob = (data) => ({
    type: ADD_JOB,
    payload:data
});
export const UpdateJob = (data) => ({
    type: UPDATE_JOB,
    payload:data
});
export const DeleteJob = (id) => ({
    type: DELETE_JOB,
    payload:id
});
export const GetJobOpenningSuccess = (data) => ({
    type: GET_JOB_OPENNING_LIST_SUCCESS,
    payload: data,
});

export const jobDetails = (id) => ({
    type: GET_JOB_DETAILS,
    payload:id
});
export const jobDetailsSuccess = (data) => ({
    type: GET_JOB_DETAILS_SUCCESS,
    payload:data
});
export const UpdateStatus = (data) => ({
    type: UPDATE_STATUS,
    payload:data
});export const ApplicantListSuccess = (data) => ({
    type: APPLICANT_LIST_SUCCESS,
    payload:data
});